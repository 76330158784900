import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Pagination`}</em>{` is used for splitting up content or data into several pages, with a control for navigating to the next or previous page.`}</p>
    <p>{`Generally, pagination is used if there are more than 25 items displayed in one view. The default number displayed will vary depending on the context.`}</p>
    <h2 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h2>
    <h4 {...{
      "id": "identify-the-current-page"
    }}>{`Identify the current page`}</h4>
    <p>{`Clearly identify which page the user is on by displaying the current page number. By providing context into how many pages there are in total (eg. 1 of 4 pages), you can help provide clarity around the data displayed.`}</p>
    <h4 {...{
      "id": "provide-various-options-for-navigating"
    }}>{`Provide various options for navigating`}</h4>
    <p><em parentName="p">{`Previous`}</em>{` and `}<em parentName="p">{`next`}</em>{` chevrons or links are the most useful way for the user to move forward or backward through pages of data. Provide an `}<a parentName="p" {...{
        "href": "/components/select"
      }}>{`inline select`}</a>{` in which users can choose the page they wish to navigate to.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABS0lEQVQoz5WSS26DUAxFeWQXbVfTTSRSu6COWzGN0klV2mlXwQBWgIQEE/7fQvg9eu0GlEiJoli6Ms92Tu4DK8qZKMtSZFmmRlGk7vd7tWkazr7vq3meq23bcg0SNI/M4pim6Q66J0kpH+is3BALaA4AfkmAUa6HYcjh8CtJkm0cx+9FUexmwfEuTVN63kLf0JoYyAJagCcxjiNngCdc7aRHZ+rjz/kMyNsBuDoGUlfK/ynZ971Es4eTHg57ep4Fh1zHDRoaR+3lAFQvAruuYyDBwjBkEACsY+DB4VngQAKPMxzydYg/i2qkuq75VcwzgLwSA19+dfEd4gc1mjpcfFZVpWN9dKwLy7ZtPQgCqn3go/0A9EQMuBaYX4AbuNggr5GfkR9vWRvXda8P0WLTKsChgCNhmqYwDENomiYsy+Ka4ziUed7zPGW+8h/thfnkcbHepgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of pagination controls on data table",
            "title": "Example of pagination controls on data table",
            "src": "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png",
            "srcSet": ["/static/facb1d9bfd75f442947813c02c285fef/d6747/pagination-usage-1.png 288w", "/static/facb1d9bfd75f442947813c02c285fef/09548/pagination-usage-1.png 576w", "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png 1152w", "/static/facb1d9bfd75f442947813c02c285fef/c3e47/pagination-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABS0lEQVQoz5WSS26DUAxFeWQXbVfTTSRSu6COWzGN0klV2mlXwQBWgIQEE/7fQvg9eu0GlEiJoli6Ms92Tu4DK8qZKMtSZFmmRlGk7vd7tWkazr7vq3meq23bcg0SNI/M4pim6Q66J0kpH+is3BALaA4AfkmAUa6HYcjh8CtJkm0cx+9FUexmwfEuTVN63kLf0JoYyAJagCcxjiNngCdc7aRHZ+rjz/kMyNsBuDoGUlfK/ynZ971Es4eTHg57ep4Fh1zHDRoaR+3lAFQvAruuYyDBwjBkEACsY+DB4VngQAKPMxzydYg/i2qkuq75VcwzgLwSA19+dfEd4gc1mjpcfFZVpWN9dKwLy7ZtPQgCqn3go/0A9EQMuBaYX4AbuNggr5GfkR9vWRvXda8P0WLTKsChgCNhmqYwDENomiYsy+Ka4ziUed7zPGW+8h/thfnkcbHepgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of pagination controls on data table",
            "title": "Example of pagination controls on data table",
            "src": "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png",
            "srcSet": ["/static/facb1d9bfd75f442947813c02c285fef/d6747/pagination-usage-1.png 288w", "/static/facb1d9bfd75f442947813c02c285fef/09548/pagination-usage-1.png 576w", "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png 1152w", "/static/facb1d9bfd75f442947813c02c285fef/c3e47/pagination-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "items-per-page"
    }}>{`Items per page`}</h4>
    <p>{`Use an inline select within the pagination bar so the user can change the amount of data displayed per page.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABOklEQVQoz5VSS0rEQBDN2F7AYwhewBFd5AS6ELyOuyCeJxuFycZ7ZBHy/39J0kn7qu3MBJxBp+DR9ePVq+7WtJWZpqn913Rd/7tJCKGdY+i/nuf5AecdAf52X8zz/ILOsizvgyD48jxv5/v+DrGFmoWcRT6hqiorjuNPnD6IBIhm8WPzmpApwudpmgSaRdu2gvxxHPf+gr7vZY5IxMqOET5lWSagaIyiiCPmqHHyi6KQoFySJJSflMBZqTwoRONC+EiEYRj2WGtAPKA2IB6gWqKu6yFNU8rzk4QgYYr4haQ3TSO6rpOrLcY5l6ArWNnJlTdK4Q3UvONBDNd1DaxuYIgEFBuoEd6g8BXDPtSjTL8UKtJzv82V+ja3wJawXllTCjdQxhzHYbZtM3wfRvdLVwJlMqY6cpfHhnwDLD7CS36054YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of items per page on data table",
            "title": "Example of items per page on data table",
            "src": "/static/993b7c3ec152cc0581ca7b1ba2465f8a/fb070/pagination-usage-2.png",
            "srcSet": ["/static/993b7c3ec152cc0581ca7b1ba2465f8a/d6747/pagination-usage-2.png 288w", "/static/993b7c3ec152cc0581ca7b1ba2465f8a/09548/pagination-usage-2.png 576w", "/static/993b7c3ec152cc0581ca7b1ba2465f8a/fb070/pagination-usage-2.png 1152w", "/static/993b7c3ec152cc0581ca7b1ba2465f8a/c3e47/pagination-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      